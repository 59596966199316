
.form-diagram-bg-gradient{
    background-image: linear-gradient(to right, #c2e2f7  0%, #a6eec6 51%, #c2e2f7  100%);
}

.diagram-number h1{
    font-size: 4.5rem;
}

.diagrams-row{
    background-color:#e7eef3;
    width: 100%;
    padding-top: 50px;
}
.diagrams{
    height: 40em;
    min-width: 22em;
  }
  
.diagrams-container-box{
justify-content:center;
align-items: center;
text-align: left;
/* align-items: flex-start; */
}
/* align-items: center; */

.diagrams-container-pie{
    height: 6.5em;
    font-size: 20px;
}

.justify-content-space-evenly{
    justify-content: space-evenly;
}
.diagrams-container-company-box{
    width: 144px;
    text-align: center;
}
/* .diagrams-container-company-box{
    width: 144px;
    text-align: center;
}
 */
.tooltip-inner-hint {
    white-space: pre-line;
    text-align: left;
} 
.tooltip-inner-hint:hover {
    z-index: 1040;
}
.formWidth{
    display: flex;
    flex-wrap: wrap;
}
html,body,.App{
    min-width: 100%;
}
#root{
    min-width: 100%;
}