.dropdown-item:hover{
  color: #6437b8!important;
}
.dropdown-item:active{
  background-color: rgba(229, 231, 235, 0.502)!important;
  color: #6437b8!important;
}
/* .LoginOkBlock{
  display: flex;
  flex-wrap: nowrap;
} */