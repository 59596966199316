.form-footerbar .container{
    color: white;
    background-color: #381c39;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.form-footerbar a{
    color: white;
}

.form-footerbar a:hover{
    color: white;
}
.form-footerbar{
    flex: 0 0 auto;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1030;
}
.footerlogo{
    width: 100%;
}