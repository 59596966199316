.border-right-left-bottom-0 th{
border-right: 0!important; 
border-left: 0!important; 
border-bottom: 0!important; 
}

.request-item-header th {
    text-align: center;
    font-family: eUkraineMedium;
    font-size: 14px!important;
    color: #677787!important;
    font-weight:normal;
}
.request-item-header td {
    font-family: eUkraineRegular;
    font-size: 14px!important;
    color: #677787!important;
}

.page-users-list-number{
    width: 40px;
    max-width: 51px;
}
.page-users-list-license{
    width: 90px;
    max-width: 100px;
}
.page-users-list-phone{
    width: 110px;
    max-width: 110px;
    min-width: 100px;
}
.page-users-list-email{
    width: 209px;
    min-width: 200px;
}
.page-users-list-company{
    min-width: 185px;
}
.btnAddUser{
    margin-left: auto!important;
}
.userListTitle{
    color: black;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media screen and (max-width:400px) {
    .btnAddUser{
        margin-left: 0!important;
        margin-top: 10px;
    }
}
.deleteBtn{
cursor: pointer;
color: red;
}

.deleteBtn:hover{
    text-decoration: underline;
}
.styleBtnDelete{
    border-radius: 50px!important;
    background-color: #381c39!important;
    border: 1px solid #381c39!important;
}
.styleBtnDelete:hover{
    background-color: transparent!important;
    color: #381c39!important;
    border: 1px solid #381c39!important;
}
.cancel{
    background-color: #677787!important;
    border: 1px solid #677787!important;
}
.cancel:hover{
    background-color: transparent!important;
    border: 1px solid #381c39!important;
}
.passWrapperNewFormAdd{
    position: relative;
}
.addFormLookNew{
    position: absolute;
    top: 35px;
}
.form_reg{
}
.form-btnAddUsers{
    background-color: #381c39!important;
    border-radius: 50px!important;
    border: 2px solid #381c39!important;
    box-shadow: none!important;
    margin-left: auto!important;
}
.form-btnAddUsers:active{
    box-shadow: none!important;
}
.form-btnAddUsers:hover{
    background-color: transparent!important;
    color: #381c39!important;
}
.cancelBtnUser{
    margin-left: 20px!important;
    background-color: #677787!important;
    border-color: #677787!important;
}
.cancelBtnUser:hover{
    color: #677787!important;
}
.styleInputRadius{
    border-radius: 0!important;
}
.modalFootStyle{
    color: red!important;
}