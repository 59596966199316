
.btn-primary-look-Login{
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background: url('/src/lib/img/eyeHide.svg') no-repeat;
  /* background: url('/src/lib/img/lockClose.svg') no-repeat; */
  width: 25px;
  height: 25px;
  border-width: thin;
  /* margin-left: -1.6em !important; */
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: transparent!important;
  border: 5px transparent!important;
  /* margin-top: 0.5em; */
  /* right: 2em; */
  /* z-index:122; */
}
.btn-primary-look-Login:focus{
  box-shadow: none!important;
}
.btn-primary-look-Login.active{
  /* box-shadow: none!important;
outline: none!important; */

    background: url('/src/lib/img/eyeShow.svg') no-repeat;
    /* background: url('/src/lib/img/lockOpen.svg') no-repeat; */
  }
#FormikLogin .form-control{
  border-radius: 0px;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}
.wrapFormik{
  display: flex;
  max-width: 100%;

  /* border: 1px solid black; */
}
#FormikLogin{
min-width: 100%;
display: flex;
flex-direction: column;
}
.auth-card{
  display: flex;
  max-width: 100%;
  margin: 0;
}
.formLogin{
  /* display: flex;
  flex-direction: column;
  max-width: 100%; */
  padding: 12px;
}
.wrapPassLine{
  position: relative;
  min-width: 100%;
}
.account-panel{
  margin: 0;
  padding: 50px;
}
.btnWrappDiv{
  flex-wrap: wrap;
}
.text-danger{
  padding-left: 12px;
}
#password{
  padding-left: 12px;
}
.auth-card-title{
  padding: 5px 12px;
  font-size: 10px!important;
}
.TitleLogin{
  padding: 5px 12px;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}
.btn-login-conteiner{
  margin-right: 20px;
}
.btn-edit{
  margin-left: 0!important;
}
@media screen and (max-width:376px) {
  .TitleLogin{
    font-size: 25px!important;
  }
  .account-panel{
    margin: 0;
    padding: 0!important;
  }
  .btn-edit{
    padding: 10px 5px!important;
  }
}
.btnOK{
  padding: 5px 10px!important;
}