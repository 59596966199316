
.form-diagram-bg-gradient{
    background-image: linear-gradient(to right, #c2e2f7  0%, #a6eec6 51%, #c2e2f7  100%);
}

.form-control-element-w7{
    width: 7em!important;
}

.form-control-element-w9{
    width: 9em!important;
}
.form-control-element-w10{
    width: 10em!important;
}
.form-control-element-w16{
    width: 16em!important;
}

.diagram-legend-bar-f{
    background:rgb(118, 42, 131);
}
.diagram-legend-bar-s{
    background:rgb(153, 112, 171);
}
.diagram-legend-bar-f, .diagram-legend-bar-s{
    min-width:3em;
    width:3em;
    height:1.5em;
    margin-right: 1em;
}

.diagram-legend-bar-container{
    padding-top: 5em;
    text-align: left;
    font-size: 18px;
    width: 50%;
}

.diagrams-container-min {
    background: #e7eef3;
    max-width: 100%;
    height: 25em;
    max-height: 35em;
    display: flex;
    justify-content: center;
}

.diagrams-box-container-min {
    max-width: 100%;
}