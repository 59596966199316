.navbar-title{
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;
    color: #767d83;
    margin-right: 6rem;
}

.navbar-title:hover{
    text-decoration: none;
    color: #767d83;
}

.nav-item{
    color: #767b83;
    display: block;
    padding: .5rem 1rem;
    transition: all 0.2s ease-in;
}

.nav-item:hover{
    color: #6437b8;
    text-decoration: none;
}

.nav-item.active{
    color: #6437b8;
}

.form-main-navbar{
    background-color: white;
}

.navbar-guest-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    min-width: 100%;
    min-height: 300px;
}
.navbar-guest-title a{
    color: black;
    height: fit-content;
}

.navbar-guest-title a:hover{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
}

.nav-guest-item{
    color: black;
    display: block;
    padding: .5rem 1rem;
    transition: all 0.2s ease-in;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 13px;
}

.nav-guest-item:hover{
    color: black;
}

.nav-guest-item.active{
    color: #6437b8;
}

.form-guest-bg-gradient{
    background-image: linear-gradient(to right, #d9c2f7  0%, #a6c1ee 51%, #7a4fa3b0  100%);
}

.form-guest-navbar-login{
    color: #fff;
    border-color: #343a40; 
    background-color: #381c39;
    text-decoration: none;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 2.75rem;
    border-radius: 10.5rem;
    max-width: 10em;
}

@media (min-width: 993px) { 
    
.form-guest-navbar-login{
    align-self: center;
}

.navbar-nav{
    align-items: center;
    align-self: center;
}
 }

.form-guest-navbar-login:hover{
    text-decoration: none;
    color: #000;
    background-color: #7a4fa300;
    border: 2px solid #381c39;
}
.home-image img{
    filter: invert(28%) sepia(13%) saturate(1269%) hue-rotate(229deg) brightness(100%) contrast(119%);
}

@media (max-width:350px )  {
    .mainTitle{
font-size: 32px;
    }
}