.passDiv{
    position: relative;
}
.lookAddForm1{
    position: absolute;
    top: 37px;
    right: 10px;
    background-color: transparent!important;
    cursor: pointer;
}
.lookAddForm1:hover{
    border: 5px transparent!important;
}
.passDivConfirm{
    position: relative;

}
.lookAddForm2{
    position: absolute;
    top: 37px;
    right: 10px;
    background-color: transparent!important;
    cursor: pointer;

}
.lookAddForm2:hover{
    border: 5px transparent!important;
}