#idCard {
    background-color: #fff0!important;
    border: 0;
}

#idTitle {
    font-family: eUkraineBold;
    background-color: #fff0!important;
    border: 0;
    color: #767d83!important;
    font-size: 18px;
}

.form-control-plaintext {
    font-family: eUkraineRegular;
    font-size: 16px!important;
    color: #677787!important;
}

#form-control-user, #form-control-user-pass {
    font-family: eUkraineRegular;
    font-size: 16px!important;
    color: #677787!important;
    border: none;
    padding: 0;
}

#btn-primary-user {
    /* background-color: #fff0;
    color: #0069d9;
    border: none;
    font-size: 16px; */
    color: #fff;
    border-color: #343a40;
    background-color: #381c39;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 2px solid transparent;
    border-radius: 10.5rem;
    font-size: 16px;
    padding: 3px 10px;
}
#btn-primary-user:hover{
    color: #381c39;
    border-color: #381c39;
    background-color: #fff;
    font-weight: 500;
}
.form-control-phone {
    min-width: 145px;
}
.row-first-child:first-child{
    margin-left: -15px;
}

.errorMessageRed{
    text-align: center;
    background-color: #ff00000d;
    padding: 1em;
    border: solid #ff000038 1px;
}
.successMessage{
    text-align: center;
    background-color: rgba(187, 246, 215, 0.16);
    padding: 1em;
    border: dashed #6437b8 0.1em;
    border-radius: 0.2em;
  }

.btn-primary-look-UserProfile{
    /* background: url('/src/lib/img/look.png') no-repeat; */
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5px;
    right: 10px;
  }
.tdLook{
    position: relative;
}
.tdPhone{
    padding-bottom: 0!important;
}