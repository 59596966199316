.additional-info-table tr th {
    font-weight: 500
}

.additional-info-table tr td,
.additional-info-table tr th {
    font-size:14px;
    vertical-align: middle;
    text-align: left;
    border-top: 2px solid #e7eef3;
    border-bottom: 2px solid #e7eef3;
    padding-top: 16px;
    padding-bottom: 16px;
    /* width: 50% */
}

@media (max-width:1024.98px) {

    .additional-info-table tr td,
    .additional-info-table tr th {
        padding-right: 32px
    }
    .additional-info-table {
        min-width: 440px;
    }
}

.additional-info-table tr td:first-child,
.additional-info-table tr th:first-child {
    font-weight: 300
}

.additional-info-table tr td:first-child.first-th,
.additional-info-table tr th:first-child.first-th {
    font-weight: 500
}

.submit-style{
    border: 2px solid transparent;
}
.submit-style:hover{
    border: 2px solid #381c39;
}
.blockFotoBtn{
    width: fit-content;
    position: relative;

}
.blockFotoBtn:hover .deleteBtnPhoto{
    opacity: 1;
}
.deleteBtnPhoto{
    opacity: 0;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #381c39;
    border: 1px solid #381c39;
    padding: 1px 5px;
    border-radius: 25px;
    background-color: white;
    transition: all 0.2s ease-in-out;
}
.deleteBtnPhoto:hover{
    color: red;
    border: 1px solid red;
}